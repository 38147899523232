import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  Button,
  Input,
  Box,
  Textarea,
  Heading,
  Text,
  Container,
  Flex,
} from "@chakra-ui/react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const SignupPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const formRef = useRef(null)
  const [values, setValues] = useState({
    company: "",
    name: "",
    email: "",
    message: "",
  })
  const [errors, setErrors] = useState({})

  const validateForm = e => {
    e.preventDefault()
    const formErrors = {}
    if (values.company.trim() === "") formErrors.company = "Navn mangler"
    if (values.name.trim() === "") formErrors.name = "Navn mangler"
    if (values.email.trim() === "" || !emailRegex.test(values.email))
      formErrors.email = "Skriv inn en gyldig epost"
    if (values.message.trim() === "") formErrors.message = "Melding mangler"
    if (Object.keys(formErrors).length > 0) setErrors(formErrors)
    else formRef.current?.submit()
  }

  const validateCompany = () => {
    if (values.company.trim() === "")
      setErrors({ ...errors, company: "Firmanavn mangler" })
    else setErrors({ ...errors, company: "" })
  }

  const validateName = () => {
    if (values.name?.trim() === "")
      setErrors({ ...errors, name: "Navn mangler" })
    else setErrors({ ...errors, name: "" })
  }

  const validateEmail = () => {
    if (values.email === "" || !emailRegex.test(values.email))
      setErrors({ ...errors, email: "Skriv inn en gyldig epost" })
    else setErrors({ ...errors, email: "" })
  }

  const validatePhone = () => {
    if (values.phone?.trim() === "")
      setErrors({ ...errors, phone: "Telefonnummer mangler" })
    else setErrors({ ...errors, phone: "" })
  }

  const validateMessage = () => {
    if (values.message.trim() === "")
      setErrors({ ...errors, message: "Melding mangler" })
    else setErrors({ ...errors, message: "" })
  }

  const intl = useIntl()

  return (
    <Layout location={location} title={siteTitle} container>
      <SEO title="Bli medlem" />
      <Flex direction={["column", "column", "row", "row"]}>
        <Box flex="1">
          <Container>
            <Heading size="lg">
              <FormattedMessage id="becomeMemberTitle" />
            </Heading>
            <form
              ref={formRef}
              id="innmelding"
              method="post"
              data-netlify="true"
              netlify-honeypot="bot-field"
              name="innmelding"
              action="/success"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Input
                type="text"
                value={values.company}
                onChange={e =>
                  setValues({ ...values, company: e.target.value })
                }
                onBlur={validateCompany}
                name="company"
                id="company"
                placeholder={intl.formatMessage({ id: "company" })}
                marginTop="3"
                isInvalid={errors.company}
              />
              {errors.company && <Text>{errors.company}</Text>}
              <Input
                type="text"
                value={values.name}
                onChange={e => setValues({ ...values, name: e.target.value })}
                onBlur={validateName}
                name="name"
                id="name"
                placeholder={intl.formatMessage({ id: "fullName" })}
                marginTop="3"
                isInvalid={errors.name}
              />
              {errors.name && <Text>{errors.name}</Text>}
              <Input
                type="email"
                value={values.email}
                onChange={e => setValues({ ...values, email: e.target.value })}
                onBlur={validateEmail}
                name="email"
                id="email"
                placeholder={intl.formatMessage({ id: "email" })}
                marginTop="3"
                isInvalid={errors.email}
              />
              {errors.email && <Text>{errors.email}</Text>}
              <Input
                type="number"
                value={values.phone}
                onChange={e => setValues({ ...values, phone: e.target.value })}
                onBlur={validatePhone}
                name="phone"
                id="phone"
                placeholder={intl.formatMessage({ id: "phone" })}
                marginTop="3"
                isInvalid={errors.phone}
              />
              {errors.phone && <Text>{errors.phone}</Text>}
              <Textarea
                name="message"
                id="message"
                placeholder={intl.formatMessage({ id: "message" })}
                rows="4"
                value={values.message}
                onChange={e =>
                  setValues({ ...values, message: e.target.value })
                }
                onBlur={validateMessage}
                marginTop="3"
                isInvalid={errors.message}
                minHeight="100px"
                maxHeight="500px"
              />
              {errors.message && <Text>{errors.message}</Text>}
              <Button
                onClick={validateForm}
                className="primary"
                disabled={
                  values.name === "" ||
                  values.email === "" ||
                  values.message === ""
                }
                marginTop="2"
              >
                {intl.formatMessage({ id: "becomeMemberSubmit" })}
              </Button>
            </form>
          </Container>
        </Box>
      </Flex>
    </Layout>
  )
}

SignupPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default SignupPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
